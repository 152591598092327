import { Component, TemplateRef, ViewChild, OnDestroy, OnInit } from '@angular/core';

import { environment } from 'environments/environment';

import { UserService } from '@services/user.service';
import { RoomService } from '@services/room.service';
import { SessionService } from '@services/session.service';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { ModalService } from '@services/support/modal.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { SwUpdate } from '@angular/service-worker';
import { LoaderService } from '@services/support/loader.service';
import { Title } from '@angular/platform-browser';
import { AccountService } from '@services/account.service';
import { StationService } from '@services/station.service';
import { MultilanguageService } from '@services/support/multilanguage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild("updateTemplate", { static: true }) private updateTemplate: TemplateRef<any>;

  private langSub: Subscription = null;

  /**
   * @param userService added for bootstrap purposes
   * @param roomService added for bootstrap purposes
   * @param sessionService added for bootstrap purposes
   * @param accountService added for bootstrap purposes
   */
  constructor(
    private userService: UserService,
    private roomService: RoomService,
    private sessionService: SessionService,
    private accountService: AccountService,
    private stationService: StationService,

    public loaderService: LoaderService,
    private titleService: Title,
    private multilanguageService: MultilanguageService,
    private modalService: ModalService,
    private flashMessageService: FlashMessageService,
    private SWUpdate: SwUpdate
  ) { }

  ngOnInit() {
    
    // Set title depend on language
    this.langSub = this.multilanguageService.onLangChange.pipe(startWith(null)).subscribe(() => {
      this.setAppName()
    });

    this.SWUpdate.available.subscribe(event => {
      const modalId = this.modalService.show({
        template: this.updateTemplate,
        context: {
          dataModel: null,
          callbacks: {
            update: () => {
              this.modalService.hide(modalId);
              this.loaderService.show();
              this.SWUpdate.activateUpdate()
              .then(() => {
                document.location.reload();
              })
              .catch(error => {
                this.loaderService.hide();
                this.flashMessageService.show('Update failed', { timeout: 10000 });
              });
            }
          }
        }
      });
    });
  }

  setAppName() {
      this.titleService.setTitle(`${environment.design.appName} ${this.multilanguageService.getInstantTranslation('TITLE')}`);
  }

  ngOnDestroy() {
    if (this.langSub) { this.langSub.unsubscribe() }
  }
}

import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, ElementRef, TemplateRef } from '@angular/core';

import { environment } from 'environments/environment';

import { Router } from '@angular/router';
import { NavigationService } from '@services/support/navigation.service';

import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { RoomService } from '@services/room.service';
import { SessionService } from '@services/session.service';
import { AccountService } from '@services/account.service';

import { DetailPageService } from '@services/support/detail-page.service';
import { ModalService } from '@services/support/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { FlashMessageService } from '@services/support/flash-message.service';
import { UtilityService } from '@services/support/utility.service';
import { LoaderService } from '@services/support/loader.service';

import { Subscription, fromEvent, combineLatest, merge, Subject, interval } from 'rxjs';
import { auditTime, debounceTime, distinctUntilChanged, first, map } from 'rxjs/operators';
import { TicketService } from '@services/ticket.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  @ViewChild("detailContainer", { read: ViewContainerRef, static: true }) detailContainer: ViewContainerRef;
  @ViewChild("detailContainer2", { read: ViewContainerRef, static: true }) detailContainer2: ViewContainerRef;

  @ViewChild("mainContent", { static: true }) mainContent: ElementRef;

  @ViewChild("idleTemplate", { static: true }) private idleTemplate: TemplateRef<any>;
  @ViewChild("sessionExportTemplate", { static: true }) private sessionExportTemplate: TemplateRef<any>;

  idleDuration: number = 600000;
  lastUserEventTime = (new Date()).getTime();
  userEventSub: Subscription = null;
  userEventTimerSub: Subscription = null;
  idleCountdownTimerSub: Subscription = null;

  sidebarOpen: boolean = false;
  private sidebarSub: Subscription = null;
  private resizeSub: Subscription = null;

  isDataLoaded: boolean = false;
  private userSubscription: Subscription = null;
  private authSubscription: Subscription = null;

  licenseMessageId: number = null;

  sessionExportSub: Subscription = null;
  accountDataSub: Subscription = null;

  sessionExportEnabled: boolean = false;
  webdavEnabled: boolean = false;

  ticketAvailable: boolean = false;
  attachedTicketId: string = null;
  attachedTicket: any = null;

  exportNameChangedSource: Subject<string> = new Subject<string>();
  exportNameAuthor: string;
  isExportNameLocked: boolean = false;

  openProfileRequest = new Subject<void>();

  validMailValidator = Validators.pattern(/^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
  invalidEmailError = {pattern: ""};
  invalidEmailMessageSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router,
    private navigationService: NavigationService,
    private userService: UserService,
    private roomService: RoomService,
    private sessionService: SessionService,
    private detailPageService: DetailPageService,
    private utilityService: UtilityService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private flashMessageService: FlashMessageService,
    private ticketService: TicketService,
    private loaderService: LoaderService
  ) {
    this.sidebarSub = this.navigationService.sidebarState.subscribe(state => {
      this.sidebarOpen = state;
    });
  }

  ngOnInit() {
    this.detailPageService.setDetailContainer(this.detailContainer);
    this.detailPageService.setDetailContainer2(this.detailContainer2);
    
    this.authSubscription = this.authService.getAuth().subscribe(auth => {
      if (!auth) {
        this.router.navigate(['/login']);
      }
    });

    combineLatest([this.authService.user, this.userService.allUsers, this.roomService.allRooms, this.sessionService.lastSessions])
    .pipe(first()).toPromise().then(() => {
      this.isDataLoaded = true;
    });

    if (window.innerWidth > 768) {
      this.navigationService.openSidebar();
    }

    this.resizeSub = fromEvent(window, "resize").pipe(auditTime(100)).subscribe(event => {
      if (window.innerWidth > 768) {
        if (!this.sidebarOpen)
          this.navigationService.openSidebar();
      } else {
        if (this.sidebarOpen)
          this.navigationService.closeSidebar();
      }
    });

    this.sessionExportSub = this.ticketService.sessionExportRequest.subscribe(([roomId, sessionId, session_ended, ticketModalId]) => { 
      if (ticketModalId) {
        this.onSessionExportModal(roomId, sessionId, session_ended, ticketModalId);
      } else {
        this.onSessionExportModal(roomId, sessionId, session_ended);
      }
    })

    this.accountDataSub = this.accountService.accountData
    .subscribe(accountData => {
      this.sessionExportEnabled = accountData.add_ons.sessionexport;
      this.webdavEnabled = accountData.add_ons.webdav;
    });

    this.accountService.accountData.pipe(first()).toPromise().then(accountData => {
      if (accountData.add_ons.dontkickinactives) {
        this.userEventSub = merge(fromEvent(document, "keydown"), fromEvent(document, "mousedown"), fromEvent(document, "mousemove"))
        .pipe(auditTime(1000)).subscribe(e => {
          this.lastUserEventTime = (new Date()).getTime();
        });
        this.startWatching();
      }
    });

    this.invalidEmailMessageSub = this.translateService.get('APP.MAIN.EMAIL_RECIPIENTS.INVALID_EMAIL').subscribe(tr => {
      this.invalidEmailError.pattern = tr;
    });

    Promise.all([
      this.accountService.getLicenseInfo().pipe(first()).toPromise(),
      this.utilityService.getServerTimeOffset()
    ])
    .then(([license, offset]) => {
      const now = Date.now();
      if (license.end > now) {
        const days = Math.ceil((license.end - now + offset) / 86400000);
        const alertType = days > 15 ? 'alert-warning' : 'alert-danger';

        if (license.demo) {
          this.flashMessageService.showTranslatedWithData('APP.MAIN.DEMO_LEFT', { appName: environment.design.appName, days: days }, { cssClass: alertType, noTimeout: true, showCloseBtn: true })
          .then(id => { this.licenseMessageId = id });
        } else if (days < 30) {
          this.flashMessageService.showTranslatedWithData('APP.MAIN.LICENSE_LEFT', { appName: environment.design.appName, days: days }, { cssClass: alertType, noTimeout: true, showCloseBtn: true })
          .then(id => { this.licenseMessageId = id });
        }
      }
    });
  }

  startWatching() {
    this.userEventTimerSub = interval(1000).subscribe(() => {
      const now = (new Date()).getTime();
      if ((now - this.lastUserEventTime) > (this.idleDuration + 30000)) {
        this.authService.logout()
          .then(() => window.location.reload());
      } else if ((now - this.lastUserEventTime) > this.idleDuration) {
        this.onIdle();
      }
    });
  }

  onIdle = () => {
    if (this.userEventTimerSub) { this.userEventTimerSub.unsubscribe() }
    const dataModel = {
      count: 30,
      countMessage: ""
    };
    this.translateService.get('APP.MAIN.IDLE_MODAL.COUNT', dataModel).toPromise()
    .then(result => {
      dataModel.countMessage = result;
    });

    const modalId = this.modalService.show({
      template: this.idleTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          ok: () => {
            if (this.idleCountdownTimerSub) { this.idleCountdownTimerSub.unsubscribe() }
            this.startWatching();
            this.modalService.hide(modalId);
          }
        }
      }
    });

    this.idleCountdownTimerSub = interval(1000).subscribe(() => {
      if (dataModel.count > 0) {
        dataModel.count--;
        this.translateService.get('APP.MAIN.IDLE_MODAL.COUNT', dataModel).toPromise()
        .then(result => {
          dataModel.countMessage = result;
        });
      } else {
        if (this.idleCountdownTimerSub) { this.idleCountdownTimerSub.unsubscribe() }
        this.modalService.hide(modalId);
        this.authService.logout();
      }
    });
  }

  getExportTimePart(timestamp: number, timezone: string) {
    const d = new Date(timestamp+UtilityService.timezoneOffset+UtilityService.timezones[timezone]);
    return "_" + d.getFullYear() + "-" +
      ("0"+(d.getMonth()+1)).slice(-2) + "-" +
      ("0" + d.getDate()).slice(-2) + "_" +
      ("0" + d.getHours()).slice(-2) + "-" +
      ("0" + d.getMinutes()).slice(-2) + "-" +
      ("0" + d.getSeconds()).slice(-2) + "_" +
      timezone;
  }

  onExportNameChange(text: string): void {
    this.exportNameChangedSource.next(text);
  }

  onSessionExportModal(roomId: string, sessionId: string, session_ended: boolean, ticketModalId?: number) {
    if (this.sessionExportEnabled && roomId && sessionId) { 
      let dataModel: { emailRecipients: any, exportName: any }, sub: Subscription;
      dataModel = {
        emailRecipients: [],
        exportName: "SessionExport"
       };
      sub = this.authService.user.pipe(
        map(u => u && u.email ? u.email : null),
        distinctUntilChanged()
      ).subscribe(email => {
        const defaultIndex = dataModel.emailRecipients.findIndex(e => e.default);
        if (email) {
          if (defaultIndex > -1) {
            dataModel.emailRecipients.splice(defaultIndex, 1, { value: email, display: email, default: true });
          } else {
            dataModel.emailRecipients.push({ value: email, display: email, default: true });
          }
        } else {
          if (defaultIndex > -1) {
            dataModel.emailRecipients.splice(defaultIndex, 1);
          }
        }
      });

      let sub2 = combineLatest([this.accountService.accountData.pipe(map(ad => ad.timezone), distinctUntilChanged()), this.sessionService.getSessionData(roomId, sessionId)])
      .subscribe(([timezone, sessionData]) => {
        if (sessionData.export_name?.name || sessionData.export_name?.name === "") {
          dataModel.exportName = sessionData.export_name?.name;
        } else {
          const dateString = this.getExportTimePart(sessionData.create_time, timezone);
          dataModel.exportName = environment.design.appName.replace(/ /g, "_").concat(dateString);
        }
        this.exportNameAuthor = sessionData.export_name?.author;
        this.isExportNameLocked = sessionData.export_name?.locked;
      });
  
      let exportNameChangedSub = this.exportNameChangedSource.pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(text => {
          if (text) {
            this.sessionService.setExportName(roomId, sessionId, text);
          }
  
      });
  
      const modalId = this.modalService.show({
        template: this.sessionExportTemplate,
        context: {
          dataModel: dataModel,
          callbacks: {
            close: () => {
              this.modalService.hide(modalId);
              if (sub) { sub.unsubscribe() }
              if (sub2) { sub2.unsubscribe() }
              if (exportNameChangedSub) { exportNameChangedSub.unsubscribe() }
            },
            openProfile: () => {
              this.openProfileRequest.next();
              this.modalService.hide(modalId);
              if (ticketModalId) { 
                this.modalService.hide(ticketModalId);
              }
              if (sub) { sub.unsubscribe() }
              if (sub2) { sub2.unsubscribe() }
              if (exportNameChangedSub) { exportNameChangedSub.unsubscribe() }
            },
            getexport: () => {
              this.loaderService.show();
              this.sessionService.exportSession(roomId, sessionId, dataModel.emailRecipients.map(email => email.value))
                .then(() => {
                  this.modalService.hide(modalId);
                  if (sub) { sub.unsubscribe() }
                  if (sub2) { sub2.unsubscribe() }
                  if (exportNameChangedSub) { exportNameChangedSub.unsubscribe() }
                  if (session_ended === false) {
                    this.flashMessageService.showTranslated("APP.MAIN.GET_EXPORT_MODAL.EXPORT_SUCCEEDED", { cssClass: "alert-info" });
                  } else {
                    this.flashMessageService.showTranslated("APP.MAIN.GET_EXPORT_MODAL.ENDED_SESSION_EXPORT_SUCCEEDED", { cssClass: "alert-info" });
                  }
                })
                .catch(error => this.flashMessageService.showTranslated("APP.MAIN.GET_EXPORT_MODAL.EXPORT_FAILED"))
                .finally(() => this.loaderService.hide());
            }
          }
        }
      })
    }
  }

  ngOnDestroy() {
    if (this.sidebarSub) { this.sidebarSub.unsubscribe() }
    if (this.resizeSub) { this.resizeSub.unsubscribe() }
    if (this.userSubscription) { this.userSubscription.unsubscribe() }
    if (this.authSubscription) { this.authSubscription.unsubscribe() }
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
    if (this.sessionExportSub) { this.sessionExportSub.unsubscribe() }
    if (this.invalidEmailMessageSub) { this.invalidEmailMessageSub.unsubscribe(); }

    if (this.idleCountdownTimerSub) { this.idleCountdownTimerSub.unsubscribe() }
    if (this.userEventSub) { this.userEventSub.unsubscribe() }
    if (this.userEventTimerSub) { this.userEventTimerSub.unsubscribe() }

    if (this.licenseMessageId) { this.flashMessageService.hide(this.licenseMessageId) }
  }

  toggleSidebar() {
    this.navigationService.toggleSidebar();
  }

  onSidebarLinkClicked() {
    this.mainContent.nativeElement.scrollTop = 0;
  }
}

<div class="session-details-container px-md-3 px-lg-5">
  <div class="container-fluid">
    <div class="card">
      <a class="back-link" (click)="onBack()">
        <i class="mi mi-arrow-back"></i><span>{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.BACK' | translate }}</span>
      </a>
      <div class="card-header d-flex justify-content-between align-items-center">
        <span class="card-header-title">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.TITLE' | translate }}</span>
        <button class="btn btn-danger" *ngIf="wipeSessionDataBtnEnabled" [disabled]="webdavEnabled || session.session_files_deleted" (click)="onWipeSessionData()" [tooltipDelay]="0" [tooltipDisabled]="!session.session_files_deleted"
          [dynamicTooltip]="'APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_DATA_WIPED'">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.WIPE_SESSION_DATA' | translate }}</button>
      </div>
      <div class="card-body px-lg-5">
        <div class="row">
          <div class="col-12 mt-2 mb-3"><span class="room-name text-muted">{{ session.room_id | roomName | async }}</span></div>
          <div class="col-md-6 mt-3"><span class="key">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.DATE' | translate }}: </span><span class="value">{{ session.create_time | utcCalc:true | async }}</span></div>
          <div class="col-md-6 mt-3"><span class="key">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.DURATION' | translate }}</span><span class="value">{{ session | sessionDuration }}</span></div>
          <div class="col my-3">
            <div class="d-flex flex-row">
              <div class="key">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.PARTICIPANTS' | translate }}: </div>
              <div class="participants col-lg-6 col-md-9">
                <span *ngFor="let name of (session.users | getIds | filterGuests:session.guest_users | userNames | async)">
                  <div *ngFor="let user of users">
                    <div class="d-flex flex-column value ml-2" *ngIf="!session.user_durations && user.name === name">
                      <div> {{ user | userUsernameRole:licenseType }} </div> 
                      <div> <a class="email-text text-info" href="mailto:{{ user.email }}">{{ user.email }}</a></div>
                    </div>
                    <div *ngFor="let duration of (session.user_durations | keyvalue)">
                      <div class="d-flex value ml-2" *ngIf="user.name === name && duration.key === user.id">
                        {{ user | userUsernameRole:licenseType }} <div class="ml-auto pl-3">
                        {{ duration.value | durationFormat: 'ms':'hhmmss' }}
                        </div>
                      </div>
                      <div class="d-flex value ml-2 mb-3" *ngIf="user.name === name && duration.key === user.id">
                        <a class="email-text text-info" href="mailto:{{ user.email }}">{{ user.email }}</a>
                      </div>
                    </div>
                  </div>
                </span>
                <span *ngFor="let guest of (session.guest_users | objectToArray)">
                  <span *ngFor="let duration of (session.user_durations | keyvalue)">
                    <div class="d-flex value ml-2 mb-3" *ngIf="duration.key === guest.id">
                      {{ guest.name }} &nbsp;  <span class="text-info">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.GUEST' | translate }}</span> <div  class="ml-auto pl-3">{{ duration.value | durationFormat: 'ms':'hhmmss' }}</div>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" >
          <div class="col-12" [ngClass]="{'col-md-6': ticketAvailable}" *ngIf="sessionExportEnabled && !webdavEnabled && !session.session_files_deleted">
            <button class="btn btn-outline-info detail-button mt-3" (click)="onSessionExportModal($event, session.room_id, session.id);">
              <img class="button-icon mr-2" src="assets/export_session.svg"><span>Get Session Export</span>   
            </button>
          </div>
          <ng-container *ngIf="ticketAvailable">
            <div class="col-12" [ngClass]="{'col-md-6': sessionExportEnabled && !webdavEnabled && !session.session_files_deleted}">
              <button *ngIf="!attachedTicketId" class="btn btn-outline-primary detail-button mt-3" (click)="onAttachToTicket()">
                <i class="mi mi-style dark-blue-color button-icon mr-2"></i><span>{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.ATTACH_TO_TICKET' | translate }}</span>   
              </button>
              <div *ngIf="attachedTicket" class="mt-4"><span class="key">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.ATTACHED_TICKET' | translate }}: </span><span class="values">{{ attachedTicket.title }}</span></div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
<!--
    <div class="card mt-4" *ngIf="sessionExportEnabled && !webdavEnabled && !session.session_files_deleted">
      <div class="card-header d-flex justify-content-between align-items-center">
        <span class="card-header-title">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_EXPORT.TITLE' | translate }}</span>
      </div>
      <div class="card-body py-4 px-4 px-md-5">
        <div class="recipients-message mb-2">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_EXPORT.MESSAGE' | translate:('design' | env) }}</div>
        <tag-input #tagInput [(ngModel)]="exportRecipients" [placeholder]="emailPholder" [secondaryPlaceholder]="emailSecPholder"
          theme="minimal" [validators]="[validMailValidator]" [errorMessages]="invalidEmailError" [animationDuration]="{enter: '0ms', leave: '0ms'}" [addOnBlur]="true"></tag-input>

          <div class="recipients-warning-text mt-2">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_EXPORT.WARNING' | translate }}</div>

        <button class="btn btn-info btn-block mt-2" [ngStyle]="{'cursor': exportRecipients.length !== 0 ? 'pointer' : 'default'}" [fakeDisabled]="exportRecipients.length === 0" (enabledClick)="onSessionExport()">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_EXPORT.SEND' | translate }}</button>
      </div>
    </div>
  -->
    <!--  ARCHIVES  -->
    <app-archive-list [room_id]="session.room_id" [session_id]="session.id"></app-archive-list>

    <div class="row files-chat">
      <div class="col-lg-6 mb-3" *ngIf="!webdavEnabled && !session.session_files_deleted">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <span class="card-header-title">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.FILES' | translate }}</span>
          </div>
          <div class="card-body">
            <app-session-file [session]="session" [session_id]="session.id"></app-session-file>
          </div>
        </div>
      </div>
      
      <div class="col-lg-6" *ngIf="!webdavEnabled && !session.session_files_deleted">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <span class="card-header-title">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.CHAT' | translate }}</span>
          </div>
          <div class="card-body">
            <app-session-chat [session]="session"></app-session-chat>
          </div>
        </div>      
      </div>
    </div>
  </div>
</div>

<ng-template #wipeSessionDataTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="card">
            <div class="card-header py-3 d-flex align-items-center justify-content-between">
              <h5 class="modal-title d-flex align-items-center">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.WIPE_SESSION_DATA_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="card-body">
              <div class="m-body padded">
                <p>{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.WIPE_SESSION_DATA_MODAL.MESSAGE' | translate }}</p>
                <p class="mt-2 text-danger font-weight-bold">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.WIPE_SESSION_DATA_MODAL.WARNING' | translate }}</p>
              </div>
              <div class="m-footer">
                <a class="text-dark" (click)="callbacks.close()">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.WIPE_SESSION_DATA_MODAL.CLOSE' | translate }}</a>
                <a class="text-danger" (click)="callbacks.wipe()">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.WIPE_SESSION_DATA_MODAL.WIPE' | translate }}</a>
              </div>
            </div>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #pickTicketTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog-wide modal-dialog" role="document">
    <div class="modal-content">
        <div class="card">
            <div class="card-header py-3 d-flex align-items-center justify-content-between">
              <h5 class="modal-title d-flex align-items-center">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.ATTACH_SESSION_TO_TICKET' | translate }}</h5>
            </div>
            <div class="card-body">
              <div class="m-body" style="height: 350px; overflow: scroll;">
                <div class="ticket-list t-container container-fluid">
                  <div class="t-head row sm-hide">
                    <div class="t-cell col-1 font-weight-bold">#</div>
                    <div class="t-cell col-5 font-weight-bold">{{ 'APP.MAIN.TICKETS.TICKET_TITLE' | translate }}</div>
                    <div class="t-cell col-3 font-weight-bold">{{ 'APP.MAIN.TICKETS.CREATED_BY' | translate }}</div>
                    <div class="t-cell col-2 font-weight-bold">{{ 'APP.MAIN.TICKETS.CREATED_ON' | translate }}</div>
                    <div class="t-cell col-1 font-weight-bold">{{ 'APP.MAIN.TICKETS.STATUS' | translate }}</div>
                  </div>

                  <div class="t-row row bg-white" style="cursor: default;" *ngIf="dataModel.tickets.length === 0">
                    <div class="no-contact-message text-muted py-2">{{ 'APP.MAIN.TICKETS.NO_TICKETS' | translate }}</div>    
                  </div>

                  <div class="t-row row" *ngFor="let t of dataModel.tickets; let i = index" [ngClass]="{'selected': selectedTicket === t.id}" (click)="onTicketClicked(t.id);">
                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">#</div>
                    <div class="t-cell col-6 col-md-4 col-lg-1">{{ i+1 }}</div>
                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.TICKETS.TICKET_TITLE' | translate }}</div>
                    <div class="t-cell col-6 col-md-4 col-lg-5">{{ t.title }}</div>
                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.TICKETS.CREATED_BY' | translate }}</div>
                    <div class="t-cell col-6 col-md-4 col-lg-3">{{ getUsersName(t.creator) }}</div>
                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.TICKETS.CREATED_ON' | translate }}</div>
                    <div class="t-cell col-6 col-md-4 col-lg-2">{{ t.create_time | utcCalc | async }}</div>
                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.TICKETS.STATUS' | translate }}</div>
                    <div class="t-cell col-6 col-md-4 col-lg-1" [ngSwitch]="t.status">
                      <div *ngSwitchCase="'open'" class="badge badge-success">{{ 'APP.MAIN.TICKETS.STATUS_OPEN' | translate }}</div>
                      <div *ngSwitchCase="'in-progress'" class="badge badge-warning">{{ 'APP.MAIN.TICKETS.STATUS_IN_PROGRESS' | translate }}</div>
                      <div *ngSwitchCase="'closed'" class="badge badge-secondary">{{ 'APP.MAIN.TICKETS.STATUS_CLOSED' | translate }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="m-footer">
                <a class="text-dark" (click)="callbacks.close()">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.CLOSE' | translate }}</a>
                <a class="text-info" [class.disabled]="!selectedTicket" (click)="selectedTicket ? callbacks.attach() : null">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.ATTACH' | translate }}</a>
              </div>
            </div>
        </div>
    </div>
  </div>
</ng-template>
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Room } from '@models/Room';
import { User } from '@models/User';
import { AccountService } from '@services/account.service';
import { AuthService } from '@services/auth.service';
import { RoomService } from '@services/room.service';
import { DetailPageService } from '@services/support/detail-page.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { LoaderService } from '@services/support/loader.service';
import { ModalService } from '@services/support/modal.service';
import { TicketService } from '@services/ticket.service';
import { UserService } from '@services/user.service';
import { Subscription } from 'rxjs';
import { startWith } from "rxjs/operators";
import { SessionDetailsComponent } from '../sessions/session-details/session-details.component';
import { Session } from '@models/Session';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { UtilityService } from '@services/support/utility.service';
import { environment } from 'environments/environment';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit, OnDestroy {

  @ViewChild("ticketTemplate", { static: true }) private ticketTemplate: TemplateRef<any>;
  @ViewChild("deleteTicketTemplate", { static: true }) private deleteTicketTemplate: TemplateRef<any>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ticketStatuses: any[] = [];
  ticketPriorities: any[] = [];
  ticketTypes: any[] = [{ id: "service-ticket", name: "Service Ticket" }];

  filteredTickets: any[] = [];
  tickets: any[] = [];
  ticketsSub: Subscription = null;

  users: User[] = [];
  usersSub: Subscription = null;

  rooms: Room[] = [];
  roomsSub: Subscription = null;

  roomsAndMeetings: Room[] = [];
  roomsAndMeetingsSub: Subscription = null;

  filterStartEnd: Date[] = null;
  filterUsers: string[] = [];
  filterRooms: string[] = [];
  filterStatuses: string[] = [];

  timezone: string = "UTC";

  selectedTicketRoomId: string = null;
  selectedTicketRoomUsers: any[] = [];

  mailToUrl: string;

  displayedColumns: string[] = ['index', 'title', 'room_id', 'creator', 'create_time', 'status'];
  dataSource: any;

  accountDataSub: Subscription = null;
  sessionExportEnabled: boolean = false;
  webdavEnabled: boolean = false;

  langSub: Subscription = null;

  constructor(
    private accountService: AccountService,
    private authService: AuthService,
    private userService: UserService,
    private roomService: RoomService,
    private ticketService: TicketService,
    private detailPageService: DetailPageService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private flashMessageService: FlashMessageService,
    private multilanguageService: MultilanguageService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.langSub =this.multilanguageService.onLangChange.pipe(startWith(this.multilanguageService.currentLang)).subscribe(change => {
      this.mapStatusAndPriority()
    });
 
    this.ticketsSub = this.accountService.accountData.pipe(
      map(ad => ad.timezone),
      distinctUntilChanged(),
      switchMap(t => {
        this.timezone = t;
        return this.ticketService.getTicketList()
      })
    )
    .subscribe(tickets => {
      this.tickets = tickets;
      this.onFilterChanged();
    });

    this.ticketsSub = this.ticketService.getTicketList().subscribe(tickets => {
      this.tickets = tickets;
      this.onFilterChanged();
    });

    this.roomsSub = this.roomService.rooms.subscribe(rooms => {
      this.rooms = rooms.map(r => {
        r.name = r.room_data.name;
        return r;
      })
      .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    });

    this.roomsAndMeetingsSub = this.roomService.allRoomsAndMeetings.subscribe(r => {
      this.roomsAndMeetings = r.map(room => {
        room.name = room.room_data.name;
        return room;
      })
      .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    });

    this.usersSub = this.userService.allUsers.subscribe(users => {
      this.users = users.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
      this.selectedTicketRoomUsers = this.selectedTicketRoomId ? this.users.filter(u => u.rooms && u.rooms[this.selectedTicketRoomId]) : [];
    });

    this.accountDataSub = this.accountService.accountData
    .subscribe(accountData => {
      this.sessionExportEnabled = accountData.add_ons.sessionexport;
      this.webdavEnabled = accountData.add_ons.webdav;
    });
  }

  mapStatusAndPriority() {
    const open = this.translateService.instant('APP.MAIN.TICKETS.STATUS_OPEN')
    const in_progress = this.translateService.instant('APP.MAIN.TICKETS.STATUS_IN_PROGRESS');
    const closed = this.translateService.instant('APP.MAIN.TICKETS.STATUS_CLOSED');
    const low = this.translateService.instant('APP.MAIN.TICKETS.LOW');
    const medium = this.translateService.instant('APP.MAIN.TICKETS.MEDIUM');
    const high = this.translateService.instant('APP.MAIN.TICKETS.HIGH');
    this.ticketStatuses = [{ id: "open", name: open }, { id: "in-progress", name: in_progress }, { id: "closed", name: closed }];
    this.ticketPriorities = [{ id: "low", name: low }, { id: "medium", name: medium }, { id: "high", name: high }]; 
  }

  onFilterChanged() {
    this.filteredTickets = this.tickets
    .filter(t => this.filterRooms.length > 0 ? this.filterRooms.includes(t.room_id) : true)
    .filter(t => this.filterStatuses.length > 0 ? this.filterStatuses.includes(t.status) : true)
    .filter(t => this.filterUsers.length > 0 ? this.filterUsers.includes(t.creator) : true)
    .filter(t => {
      if (!this.filterStartEnd) { return true; }
      // Duplicate dates
      const start = new Date(this.filterStartEnd[0].getTime());
      const end = new Date(this.filterStartEnd[1].getTime());
      // Set hours to edges (midnight) in browser timezone
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);
      // Calculate midnight of account timezone
      const startTime = start.getTime()-UtilityService.timezoneOffset-UtilityService.timezones[this.timezone];
      const endTime = end.getTime()-UtilityService.timezoneOffset-UtilityService.timezones[this.timezone];
      // If this tickets' create time not inside this dates, filter it
      return startTime < t.create_time && t.create_time < endTime;
    });
    this.dataSource = new MatTableDataSource([...this.filteredTickets]);
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    if (this.ticketsSub) { this.ticketsSub.unsubscribe() }
    if (this.roomsSub) { this.roomsSub.unsubscribe() }
    if (this.roomsAndMeetingsSub) { this.roomsAndMeetingsSub.unsubscribe() }
    if (this.usersSub) { this.usersSub.unsubscribe() }
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
    if (this.langSub) { this.langSub.unsubscribe() }
  }

  onTicketClicked(ticket: any) {
    const dataModel: any = {
      ticket: {
        status: "open",
        priority: "medium",
        followup: null,
        type: "service-ticket",
        assignee: null,
        title: "",
        description: "",
        resolution: "",
        room_id: this.rooms[0] ? this.rooms[0].id : null
      },
      sessions: [],
      pristine: true,
      mode: "detail"
    }
    const ticketSub = this.ticketService.getTicketData(ticket.id)
    .subscribe(t => {
      if (!t.priority) { t.priority = "medium"; }
      if (!t.resolution) { t.resolution = ""; }
      if (!t.assignee) { t.assignee = null; }

      dataModel.ticket = {
        status: t.status,
        priority: t.priority,
        followup: t.followup ? new Date(t.followup) : null,
        create_time: t.create_time,
        creator: t.creator,
        type: t.type,
        assignee: t.assignee,
        title: t.title,
        description: t.description,
        resolution: t.resolution,
        room_id: t.room_id
      };
      dataModel.orjTicket = {
        status: t.status,
        priority: t.priority,
        followup: t.followup ? new Date(t.followup) : null,
        type: t.type,
        assignee: t.assignee,
        title: t.title,
        description: t.description,
        resolution: t.resolution,
        room_id: t.room_id
      };
      this.selectedTicketRoomId = t.room_id;
      this.selectedTicketRoomUsers = this.selectedTicketRoomId ? this.users.filter(u => u.rooms && u.rooms[this.selectedTicketRoomId]) : [];

      this.generateMailToUrl(dataModel);
    });
    const ticketSessionsSub = this.ticketService.getTicketSessions(ticket.id)
    .subscribe(sessions => {
      dataModel.sessions = sessions;
      this.calculateSessionStats(sessions)
      .then(stats => {
        dataModel.sessionStats = stats;
        this.generateMailToUrl(dataModel);
      });
    });
    let ticketFormSub;
    dataModel.modalId = this.modalService.show({
      template: this.ticketTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          edit: (ticketForm: NgForm) => {
            ticketSub.unsubscribe();
            ticketFormSub = ticketForm.valueChanges.subscribe(val => {
              const changes = this.detectTicketChanges(val, dataModel.orjTicket);
              dataModel.pristine = Object.keys(changes).length === 0;
            });
            dataModel.mode = "edit";
          },
          save: (ticketForm: NgForm) => {
            if (dataModel.pristine) {
              return;
            }
            const changes = {
              id: ticket.id,
              properties: this.detectTicketChanges(ticketForm.value, dataModel.orjTicket)
            };
            this.loaderService.show();
            this.ticketService.updateTicket(changes)
            .then(result => {
              if (ticketFormSub) { ticketFormSub.unsubscribe(); }
              if (ticketSessionsSub) { ticketSessionsSub.unsubscribe(); }
              this.modalService.hide(dataModel.modalId);
              this.onTicketClicked(ticket);
            })
            .catch(error => this.flashMessageService.show('There is an error occured.'))
            .finally(() => this.loaderService.hide());
          },
          deleteTicket: () => {
            const deleteMId = this.modalService.show({
              template: this.deleteTicketTemplate,
              context: {
                dataModel: dataModel.ticket,
                callbacks: {
                  cancel: () => this.modalService.hide(deleteMId),
                  delete: () => {
                    this.loaderService.show();
                    this.ticketService.deleteTicket(ticket.id)
                    .then(() => {
                      this.modalService.hide(deleteMId);
                      this.modalService.hide(dataModel.modalId);
                    })
                    .catch(error => this.flashMessageService.show('There is an error occured.'))
                    .finally(() => this.loaderService.hide());
                  }
                }
              }
            });
          },
          close: () => {
            ticketSub.unsubscribe();
            ticketSessionsSub.unsubscribe();
            this.modalService.hide(dataModel.modalId);
          },
          cancelEdit: () => {
            ticketSessionsSub.unsubscribe();
            if (ticketFormSub) { ticketFormSub.unsubscribe(); }
            this.modalService.hide(dataModel.modalId);
          },
          roomChanged: (room) => {
            this.selectedTicketRoomId = room.id;
            this.selectedTicketRoomUsers = this.selectedTicketRoomId ? this.users.filter(u => u.rooms && u.rooms[this.selectedTicketRoomId]) : [];
            if (dataModel.ticket.assignee && this.selectedTicketRoomUsers.findIndex(u => u.id === dataModel.ticket.assignee) < 0) {
              dataModel.ticket.assignee = null;
            }
          }
        }
      }
    });
  }

  generateMailToUrl(dataModel: any) {
    if (!(dataModel.ticket && dataModel.sessions && dataModel.sessionStats)) {
      return;
    }
    const ticket = dataModel.ticket;
    const sessions = dataModel.sessions;
    const sessionStats = dataModel.sessionStats;

    const getUserName = (id: string) => {
      const user = this.users.find(u => u.id === id);
      return user ? user.name : "Unknown";
    }
    const getRoomName = (id: string) => {
      const room = this.roomsAndMeetings.find(r => r.id === id);
      return room ? room.name : "Unknown";
    }
    const getTimeString = (timestamp: number, timezone: string, appendTimezone: boolean) => {
      const d = new Date(timestamp+UtilityService.timezoneOffset+UtilityService.timezones[timezone]);
      const formatted = ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
      return appendTimezone ? `${formatted} ${timezone}` : formatted;
    }
    const getTimeString2 = (d: Date) => {
      return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + d.getFullYear();
    }
    const getSessionDuration = (session: any) => {
      return session.start_time && session.end_time ? Math.ceil((session.end_time - session.start_time) / 60000) + ' min' : '-';
    }

    let participants = "";
    const userIds = Object.keys(sessionStats.users);
    for (const uid of userIds) {
      participants = `${participants}
      ${getUserName(uid)} ${sessionStats.users[uid].count} Sessions`
    }
    if (userIds.length === 0) {
      participants = `
      `;
    }
    let stats = `Attached Session Count: ${sessionStats.count}
    Total Session Duration: ${sessionStats.duration}

    Participants: ${participants}`;

    let sessionList = "";
    sessions.forEach((s, i) => {
      sessionList = `${sessionList}
      ${i+1}. ${getTimeString(s.create_time, this.timezone, true)} ${getSessionDuration(s)}`;
    });
    if (sessions.length === 0) {
      sessionList = `
      `;
    }

    // Do not delete new lines in text, they are necessary...
    const body = `${environment.design.appName} Ticket Export on ${getTimeString(ticket.create_time, this.timezone, false)} for ${ticket.title} has been created:

    Title         : ${ticket.title}
    Description   : ${ticket.description}
    Resolution    : ${ticket.resolution ? ticket.resolution : ''}

    Status        : ${this.ticketStatuses.find(s => s.id === ticket.status).name}
    Priority      : ${this.ticketPriorities.find(p => p.id === ticket.priority).name}

    Room          : ${getRoomName(ticket.room_id)}
    Created on    : ${getTimeString(ticket.create_time, this.timezone, true)}
    Created by    : ${getUserName(ticket.creator)}
    Assignee      : ${ticket.assignee ? getUserName(ticket.assignee) : '' }
    Followup Date : ${ticket.followup ? getTimeString2(ticket.followup) : '' }

    ${stats}

    Attached Sessions:${sessionList}`;

    this.mailToUrl = `mailto:?subject=${encodeURIComponent(ticket.title)}&body=${encodeURIComponent(body)}`;
  }

  async calculateSessionStats(sessions: Session[]) {
    return new Promise(resolve => {
      const stats: any = {};
      stats.count = sessions.length;
      stats.duration = 0;
      stats.users = {};
      sessions.forEach(session => {
        const sessionDuration = session.start_time && session.end_time ? session.end_time - session.start_time : 0;
        stats.duration = stats.duration + sessionDuration;
        (session.users ? Object.keys(session.users) : []).forEach(userId => {
          if (!stats.users[userId]) {
            stats.users[userId] = {
              count: 0,
              duration: 0
            }
          }
          stats.users[userId].count = stats.users[userId].count + 1;
          stats.users[userId].duration = stats.user_durations && stats.user_durations[userId] ? stats.user_durations[userId] : 0;
        });
      });
      stats.duration = `${Math.ceil(stats.duration / 60000)} min`
      resolve(stats);
    });
  }

  formatDate(d: Date): string {
    return `${d.getFullYear()}-${("0"+(d.getMonth()+1)).slice(-2)}-${("0"+d.getDate()).slice(-2)}`;// YYYY-MM-DD
  }

  onSessionDetails(session: any, modalId: number) {
    this.modalService.hide(modalId);
    const detail = this.detailPageService.loadComponent2(SessionDetailsComponent).instance;
    detail.session = session;
  }

  onSessionExportModal($event, roomId:string, sessionId: string, modalId: number) {
    $event.stopPropagation();
    this.ticketService.sessionExportRequest.next([roomId,sessionId, true, modalId]);
  }

  detectTicketChanges(formValue: any, orjTicket: any) {
    const simpleTicketProps = Object.keys(orjTicket).filter(p => p !== 'followup');
    const changes: any = simpleTicketProps.reduce((changes, prop) => {
      if (formValue[prop] !== orjTicket[prop]) {
        changes[prop] = formValue[prop];
      }
      return changes;
    }, {});

    if (formValue.followup) {
      if (!orjTicket.followup) {
        changes.followup = this.formatDate(formValue.followup);
      } else if (this.formatDate(formValue.followup) !== this.formatDate(orjTicket.followup)) {
        changes.followup = this.formatDate(formValue.followup);
      }
    } else if (orjTicket.followup) {
      changes.followup = null;
    }
    return changes;
  }

  onCreateTicket() {
    const rid = this.rooms[0] ? this.rooms[0].id : null;
    const dataModel = {
      ticket: {
        status: "open",
        priority: "medium",
        followup: null,
        type: "service-ticket",
        assignee: null,
        title: "",
        description: "",
        resolution: "",
        room_id: rid
      },
      mode: "create"
    }
    this.selectedTicketRoomId = rid;
    this.selectedTicketRoomUsers = this.selectedTicketRoomId ? this.users.filter(u => u.rooms && u.rooms[this.selectedTicketRoomId]) : [];

    const modalId = this.modalService.show({
      template: this.ticketTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          close: () => this.modalService.hide(modalId),
          roomChanged: (room) => {
            this.selectedTicketRoomId = room.id;
            this.selectedTicketRoomUsers = this.selectedTicketRoomId ? this.users.filter(u => u.rooms && u.rooms[this.selectedTicketRoomId]) : [];
            if (dataModel.ticket.assignee && this.selectedTicketRoomUsers.findIndex(u => u.id === dataModel.ticket.assignee) < 0) {
              dataModel.ticket.assignee = null;
            }
          },
          create: (ticketForm: NgForm) => {
            const ticketData: any = {
              type: ticketForm.value.type,
              assignee: ticketForm.value.assignee,
              creator: this.authService.currentUser.id,
              description: ticketForm.value.description,
              //followup: new Date().getTime(),
              priority: ticketForm.value.priority,
              resolution: ticketForm.value.resolution,
              room_id: ticketForm.value.room_id,
              status: ticketForm.value.status,
              title: ticketForm.value.title
            };
            if (ticketForm.value.followup) {
              ticketData.followup = this.formatDate(ticketForm.value.followup);
            }
            if (ticketForm.value.participants) {
              ticketData.participants = ticketForm.value.participants;
            }
            this.loaderService.show();
            this.ticketService.createTicket(ticketData)
            .then(result => this.modalService.hide(modalId))
            .catch(error => this.flashMessageService.show('There is an error occured.'))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { SessionService } from '@services/session.service';
import { Subscription } from 'rxjs';
import { Session } from '@models/Session';
import { User } from '@models/User';
import { UserService } from '@services/user.service';
import { DetailPageService } from '@services/support/detail-page.service';
import { SessionDetailsComponent } from './session-details/session-details.component';
import { RoomService } from '@services/room.service';
import { Room } from '@models/Room';
import { AccountService } from '@services/account.service';
import { UtilityService } from '@services/support/utility.service';

import { saveAs } from "file-saver";

import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {

  sessions: Session[] = null;
  allSessions: Session[] = null;
  sessionSub: Subscription = null;

  users: User[] = [];
  userSub: Subscription = null;

  rooms: Room[] = [];
  roomsSub: Subscription = null;

  roomsAndMeetings: Room[] = [];
  roomsAndMeetingsSub: Subscription = null;

  page: number = 1;
  offset: number = 30;
  pageCount: number = 1;

  filterStartEnd: Date[] = [];
  currentStart: Date;
  currentEnd: Date;
  filterUsers: string[] = [];
  filterRooms: string[] = [];

  sessionStats: any = null;

  accountDataSub: Subscription = null;
  timezone: string = "UTC";
  differenceBetweenTimezones: number = 0;
  isFilterAvailable = true

  showSessionRatingFilters = false;
  sessionRatingFilters = {
    eq1: null,
    fq1: null,
    fq2: null,
    fq3: null,
    fq4: null
  }

  constructor(
    private accountService: AccountService,
    private userService: UserService,
    private roomService: RoomService,
    private sessionService: SessionService,
    private detailPageService: DetailPageService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.accountDataSub = this.accountService.accountData.subscribe(accountData => {
      this.showSessionRatingFilters = accountData.add_ons.showclientsessionrating;
      this.timezone = accountData.timezone;
      this.filterSessions(this.timezone);
    });
    this.userSub = this.userService.allUsers.subscribe(u => {
      this.users = u.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    });
    this.roomsSub = this.roomService.allRooms.subscribe(r => {
      this.rooms = r.map(room => {
        room.name = room.room_data.name;
        return room;
      })
      .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    });
    this.roomsAndMeetingsSub = this.roomService.allRoomsAndMeetings.subscribe(r => {
      this.roomsAndMeetings = r.map(room => {
        room.name = room.room_data.name;
        return room;
      })
      .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    });
  }

  async filterSessions(timezone: string) {
    const offset = await this.utilityService.getServerTimeOffset();

    const currentTimeInBrowserTimezone = new Date().getTime();
    const currentTimeInAccountTimezone = new Date(Date.now() + offset + UtilityService.timezoneOffset + UtilityService.timezones[timezone]).getTime();

    this.differenceBetweenTimezones = currentTimeInAccountTimezone - currentTimeInBrowserTimezone;
    
    const todayTimestampOffsetAdded = currentTimeInAccountTimezone;
    const oneWeekAgoTimestamp = 604800000; //equals to 7 days
    const oneWeekDifference = todayTimestampOffsetAdded - oneWeekAgoTimestamp;

    const oneWeekAgo = this.setSessionDateFilterValues(new Date(oneWeekDifference), 0, 0, 0, 0);
    const today = this.setSessionDateFilterValues(new Date(todayTimestampOffsetAdded), 23, 59, 59, 59);
    this.filterStartEnd = [oneWeekAgo, today];
  
    this.applyFilters();
  }

  setSessionDateFilterValues(date: Date, ...time) {
    date.setHours(time[0]);
    date.setMinutes(time[1]);
    date.setSeconds(time[2]);
    date.setMilliseconds(time[3]);

    return date;
  }

  onSessionExport() {
    let exportText = "\uFEFFCreated At,Room,Duration (min),Participants\n";
    for (const s of this.allSessions) {
      let sessionText = this.formatDate(s.create_time) + ",";
      const room = this.roomsAndMeetings.find(r => r.id === s.room_id);
      sessionText = sessionText + (room ? room.name : "Unknown") + ",";
      sessionText = sessionText + this.getSessionDuration(s) + ",";

      if (s.users || s.guest_users) {
        sessionText = sessionText + "\"";
        const sessionUsers = s.users ? Object.keys(s.users) : [];
        sessionUsers.forEach((uid, i) => {
          const user = this.users.find(u => u.id === uid);
          sessionText = sessionText + (user ? user.name : "Unknown") + (sessionUsers.length - 1 !== i ? "," : "");
        });
        if (s.users && s.guest_users) {
          sessionText = sessionText + ",";
        }
        const guestUsers = s.guest_users ? Object.keys(s.guest_users) : [];
        guestUsers.forEach((uid, i) => {
          sessionText = sessionText + s.guest_users[uid].name + " (Guest)" + (guestUsers.length - 1 !== i ? "," : "");
        });
        sessionText = sessionText + "\"";
      }
      exportText = exportText + sessionText + "\n";
    }
    const blob = new Blob([exportText], {type: "text/csv;charset=utf-8;"});
    const now = new Date();
    const filename = `${environment.design.appName.split(' ').join('_')}_Session_Export_${now.getFullYear()}_${("0"+(now.getMonth()+1)).slice(-2)}_${("0"+now.getDate()).slice(-2)}.csv`;
    saveAs(blob, filename);
  }

  formatDate(timestamp: number) {
    const d = new Date(timestamp+UtilityService.timezoneOffset+UtilityService.timezones[this.timezone]);
    return `${("0"+d.getDate()).slice(-2)}.${("0"+(d.getMonth()+1)).slice(-2)}.${d.getFullYear()} ${("0" + d.getHours()).slice(-2)}:${("0" + d.getMinutes()).slice(-2)} ${this.timezone}`;
  }

  getSessionDuration(value: Session) {
    return value.start_time && value.end_time ? Math.ceil((value.end_time - value.start_time) / 60000) : 0;
  }

  applyFilters() {
    this.page = 1;
    if(this.filterStartEnd?.[0]) {
      this.currentStart = this.setSessionDateFilterValues(this.filterStartEnd[0], 0, 0, 0, 0);
    }
    if(this.filterStartEnd?.[1]) {
      this.currentEnd = this.setSessionDateFilterValues(this.filterStartEnd[1], 23, 59, 59, 59);
    }

    const filteringAccountBasedTimezoneStartTime = this.currentStart.getTime()-this.differenceBetweenTimezones;
    const filteringAccountBasedTimezoneEndTime = this.currentEnd.getTime()-this.differenceBetweenTimezones;

    if (this.sessionSub) { this.sessionSub.unsubscribe() }
    // do not change filters values when sessions updated
    const rooms = [...this.filterRooms];
    const users = [...this.filterUsers];
    this.sessionSub = this.getSessions(filteringAccountBasedTimezoneStartTime, filteringAccountBasedTimezoneEndTime).subscribe(sessions => {
      if (sessions) {
        this.allSessions = sessions.filter(session => {
          let roomSelected = rooms.length > 0 ? rooms.includes(session.room_id) : true;
          let userSelected = users.length > 0 ? users.some(id => (session.users ? Object.keys(session.users) : []).includes(id)) : true;

          const e1 = !this.sessionRatingFilters.eq1 || (this.sessionRatingFilters.eq1 && session.session_ratings && session.session_ratings.expert && (session.session_ratings.expert.IS_TOOL_ACCURATE <= this.sessionRatingFilters.eq1))
          const f1 = !this.sessionRatingFilters.fq1 || (this.sessionRatingFilters.fq1 && session.session_ratings && session.session_ratings.field && (session.session_ratings.field.SUPPORT_HELPFUL <= this.sessionRatingFilters.fq1))
          const f2 = !this.sessionRatingFilters.fq2 || (this.sessionRatingFilters.fq2 && session.session_ratings && session.session_ratings.field && (session.session_ratings.field.CLEAR_AND_EASY <= this.sessionRatingFilters.fq2))
          const f3 = !this.sessionRatingFilters.fq3 || (this.sessionRatingFilters.fq3 && session.session_ratings && session.session_ratings.field && (session.session_ratings.field.IS_RESOLVED <= this.sessionRatingFilters.fq3))
          const f4 = !this.sessionRatingFilters.fq4 || (this.sessionRatingFilters.fq4 && session.session_ratings && session.session_ratings.field && (session.session_ratings.field.USE_IN_FUTURE <= this.sessionRatingFilters.fq4))
          return roomSelected && userSelected && e1 && f1 && f2 && f3 && f4;
        });

        this.calculateStats(this.allSessions)
        .then(stats => { this.sessionStats = stats });

        this.pageCount = Math.ceil( this.allSessions.length / this.offset );
        this.sessions = this.allSessions.slice((this.page - 1) * this.offset, this.page * this.offset);
      }
    });
  }

  getSessions(filteringAccountBasedTimezoneStartTime, filteringAccountBasedTimezoneEndTime) {
    this.isFilterAvailable = !(!this.filterStartEnd?.[0] || !this.filterStartEnd?.[1])
    return (!this.isFilterAvailable ? this.sessionService.getSessionsAll() : this.sessionService.getSessionsIn(filteringAccountBasedTimezoneStartTime, filteringAccountBasedTimezoneEndTime)) 
  }

  async calculateStats(sessions: Session[]) {
    return new Promise(resolve => {
      const stats: any = {};
      stats.count = sessions.length;
      stats.duration = 0;
      stats.users = {};
      sessions.forEach(session => {
        const sessionDuration = session.start_time && session.end_time ? session.end_time - session.start_time : 0;
        stats.duration = stats.duration + sessionDuration;
        (session.users ? Object.keys(session.users) : []).forEach(userId => {
          if (!stats.users[userId]) {
            stats.users[userId] = {
              count: 0,
              duration: 0
            }
          }
          stats.users[userId].count = stats.users[userId].count + 1;
          stats.users[userId].duration = stats.user_durations && stats.user_durations[userId] ? stats.user_durations[userId] : 0;
        });
      });
      stats.duration = `${Math.ceil(stats.duration / 60000)} min`
      resolve(stats);
    });
  }

  ngOnDestroy() {
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
    if (this.userSub) { this.userSub.unsubscribe() }
    if (this.sessionSub) { this.sessionSub.unsubscribe() }
    if (this.roomsSub) { this.roomsSub.unsubscribe() }
    if (this.roomsAndMeetingsSub) { this.roomsAndMeetingsSub.unsubscribe() }
  }

  prev() {
    this.page = this.page - 1;
    this.sessions = this.allSessions.slice((this.page - 1) * this.offset, this.page * this.offset);
  }

  next() {
    this.page = this.page + 1;
    this.sessions = this.allSessions.slice((this.page - 1) * this.offset, this.page * this.offset);
  }

  onSessionDetails(session: Session) {
    const detail = this.detailPageService.loadComponent2(SessionDetailsComponent).instance;
    detail.session = session;
  }

  getUserNames(userList: any) {
    return (userList ? Object.keys(userList).map(userId => {
      const u = this.users.find(u => u.id === userId);
      return u ? u.name : null;
    }) : [])
    .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
  }
/*
  ## GET PAGE BY PAGE

  nextKey: string = null;
  prevKeys: string[] = [];

  page: number = 1;
  offset: number = 10;

  ngOnInit() {
    this.getSessions();
  }

  getSessions(key?) {
    if (this.sessionSub) { this.sessionSub.unsubscribe() }
    this.sessionSub = this.sessionService.getSessions(this.offset, key).subscribe(ss => {
      this.nextKey = ss[this.offset] ? ss[this.offset].id : null;
      this.sessions = ss.slice(0, this.offset);

      console.log(this.sessions)
    });
  }

  prev() {
    this.page = this.page - 1;
    let key = this.prevKeys.pop();
    key = this.prevKeys.length === 0 ? null : key;
    this.getSessions(key);
  }

  next() {
    this.page = this.page + 1;
    this.getSessions(this.nextKey);
    this.prevKeys.push(this.sessions[0].id);
  }
*/
}

<div class="container-fluid mt-2">
    <div class="row">
        <div class="col-12 px-0 mb-3">
            <app-info-boxes></app-info-boxes>
        </div>
        <div class="col-12 px-0 mb-4">
            <app-session-charts></app-session-charts>
        </div>
        <div class="col-12 px-0 mb-4">
            <app-session-list [type]="'last'"></app-session-list>
        </div>
        <!--<div class="col-12 col-xl-8 pl-0 pr-0 pr-xl-2 mb-4 mb-xl-0">
            <app-room-list [detailed]="false"></app-room-list>
        </div>
        <div class="col-12 col-xl-4 pr-0 pl-0 pl-xl-2">
            <app-user-list [detailed]="false"></app-user-list>
        </div>-->
    </div>
</div>

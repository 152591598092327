<div class="terms-container">
  <div class="bar-container">
    <div class="logo-container" *ngIf="('design' | env).legalLogoText"><img class="logo-image" [src]="('design' | env).legalLogo"><span>{{ ('design' | env).legalLogoText }}</span></div>
    <div class="logo-container" *ngIf="!('design' | env).legalLogoText"><img class="logo-only-image" [class.wide]="('design' | env).wideLegalLogo" [src]="('design' | env).legalLogo"></div>
    <a routerLink="/login" class="back-link text-info mr-3"></a>
  </div>
  
  <div class="terms-content">
    <div class="terms-title">Terms of Service</div>

    <p class="mb-4">Last updated: September 23th, 2020</p>

    <div class="terms-title-secondary">1. General</div>
    <p>These General Terms apply generally to the use of the service {{('design' | env).legalName }} (the "Service" or “{{('design' | env).legalName }}”), and constitute a contract between you and the company providing the Service, UAB, {{('design' | env).legalAppName | uppercase }}. ("The Company"). These terms govern your access to and use of the {{('design' | env).legalName }} application and website (the "Service"), and any video, sound, text, graphics, or other materials sent, received, stored or otherwise appearing in the Service (collectively referred to as "Content"). Parts of the Service may display Content that is not {{('design' | env).legalName }}'s (“User Content”). Such content is the sole responsibility of the person or entity that has made it available.</p>
    <p>Where applicable, "The Company" shall also be understood as a reference to affiliates, suppliers, partners and other third parties {{('design' | env).legalName }} may engage or otherwise cooperate with in connection with the Service.</p>
    <p>By using {{('design' | env).legalName }} you agree to these General Terms and the Privacy Policy (collectively referred to as "Terms"). Please read them carefully. If you don’t agree to the Terms, you can’t use {{('design' | env).legalName }}.</p>
    <p>We can change these Terms at any time. If you don’t agree to them, you should delete your account before they take effect, otherwise your use of the Service and Content will be subject to the new Terms.</p>

    <div class="terms-title-secondary">2. Age limitation</div>
    <p>{{('design' | env).legalName }} is only for people 16 years old and over. By using {{('design' | env).legalName }}, you affirm that you are over 16. If we learn that someone under 16 is using {{('design' | env).legalName }}, we’ll terminate their account.</p>

    <div class="terms-title-secondary">3. How you can use the Service</div>
    <p>{{('design' | env).legalName }} is for legitimate business use only. Your use of {{('design' | env).legalName }} requires that you have hardware, software and an Internet connection fulfilling certain recommended requirements, as may be specified in our FAQ. If the recommended requirements are not met, you may potentially still use the Service, but normally with a lower quality or performance. Such reduced quality or performance will not give you the right to claim any compensation from {{('design' | env).legalName }}.</p>
    <p>To use certain parts of the {{('design' | env).legalName }} service, you will need to create a business {{('design' | env).legalName }} account. You may register an account by following the instructions on the Website or other instructions prompted to you. You are responsible for providing and maintaining accurate and updated personal information, and for safeguarding your account information. You may not select or use an identity of another person with the intent to impersonate that person. You must use a valid business e-mail address, and {{('design' | env).legalName }} reserves the right to verify this at any time. {{('design' | env).legalName }} will not be liable for any loss or damage arising from your failure to comply with the above requirements.</p>
    <p>Your account is strictly personal and shall not be used by any other person without your supervision. Neither shall you assign your account to any other person. You are in any event solely responsible for the use of the Service through your business user account.</p>
    <p>We may change, terminate, or restrict access to any aspect of the Service or your account, at any time, without notice. We reserve the right to impose limitations of use based on what we consider fair or legitimate usage.</p>
    <p>You are responsible for your use of the {{('design' | env).legalName }}, including the lawfulness of any content displayed, shared, uploaded or otherwise made available by you in the Service (“the User Content”). User Content includes room names, and you are responsible for ensuring room names does not include Prohibited User Content (as listed below). Do not include information that you do not want to make public in room names.</p>

    <div class="terms-title-third">Restrictions on Content and Use of the Service</div>
    <p>We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce the Terms, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v) protect our rights, property or safety, our users and the public.</p>
    <p>We reserve the right to report illegal activity to applicable local authorities.</p>
    <p>Prohibited User Content includes, without limitation, content which:</p>

    <ul>
      <li>is offensive, such as User Content that engages in, endorses or promotes racism, bigotry, discrimination, hatred, harassment or physical harm of any kind against any group or individual;</li>
      <li>displays or links to pornographic, sexually explicit or any other indecent material;</li>
      <li>promotes or endorses false or misleading information or illegal activities or conduct that is abusive, threatening, obscene, defamatory or libelous;</li>
      <li>promotes or endorses an illegal or unauthorised copy of another person's copyrighted work;</li>
      <li>infringes on other’s trademarks, copyright or legal rights</li>
      <li>contains restricted or password only access pages or hidden pages or images;</li>
      <li>solicits passwords or personal data from other users; or</li>
      <li>violates the rights of or harms or threatens the safety of other users or the Service.</li>
      <li>shares Personal information about others, without their consent</li>
    </ul>

    <p>Any use or reliance on any Content or materials posted via the Service or obtained by you through the Service is at your own risk. We do not endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any Content or communications posted via the Services or endorse any opinions expressed via the Service. You understand that by using the Service, you may be exposed to Content that might be offensive, harmful, inaccurate or otherwise inappropriate, or in some cases, Content that have been mislabeled or is otherwise deceptive. Under no circumstances will we be liable in any way for any Content, including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of any Content generated or made available via the Service.</p>
    <p>We give you a personal, limited, worldwide, royalty-free, non-assignable, non-sublicensable and non-exclusive license to use the software that is provided to you by us as part of the Service for your business use. This license is for the sole purpose of enabling you to use the Service as provided by us, subject to these Terms.</p>
    <p>You may not do any of the following while accessing or using the Services: (i) access, tamper with, or use non-public areas of the Service, our internal computer systems including technical delivery systems of our subcontractors used to provide the Service; (ii) probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures; (iii) access or search or attempt to access or search the Service by any means (automated or otherwise) other than through our currently available, published interfaces that are provided by us (and only pursuant to those terms and conditions), unless you have been specifically allowed to do so in a separate agreement with us; (iv) forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Service to send altered, deceptive or false source-identifying information; or (v) interfere with, or disrupt, (or attempt to do so), the access of any user, host or network, or by scripting the creation of Content in such a manner as to interfere with or create an undue burden on the Service or its users.</p>
    <p>The Service may contain cryptographic functionality where the export of such could be restricted under applicable export control laws. You shall not export or re-export the Service or parts of it in violation of such laws or regulations.</p>

    <div class="terms-title-third">Charging for Use of the Services</div>
    <p>The Service is offered as a paid business version. You can choose to upgrade your account, which will give you additional features in the rooms you own. The additional features will then be available to all users in your corporate, even if they’re anonymous. We reserve the right to change the features included in our plans, the price of the additional plans and the structure of the plans at any time. We will notify all paying users of material changes to the content, price and terms of the plan.</p>
    <p>All payments shall be made in. Should you choose to re-activate your subscription during the period after cancellation, you will start a new subscription from the end date of the previous subscription, and will continue to have additional features.</p>

    <div class="terms-title-secondary">4. Privacy and Personal Data</div>
    <p>When delivering the Service, {{('design' | env).legalName }} will collect and process personal data about you and your use of the Service. The updated Privacy Policy is available on <a class="text-info" routerLink="/privacy">this page.</a> By using our Service, you agree that {{('design' | env).legalName }} can use your personal data in accordance with our Privacy Policy and in accordance with the consents you have given us.</p>
    <p>{{('design' | env).legalName }} will comply with EU privacy regulations.</p>

    <div class="terms-title-secondary">5. Communication</div>
    <p>{{('design' | env).legalName }} may need to send you information about the Service, such as important service announcements and administrative messages, by SMS, e-mail or other means of electronic communication, by posting a notice on the Website, or through any other relevant communication channels.</p>
    <p>{{('design' | env).legalName }} may offer to send you promotional information by SMS, e-mail or other means of electronic communication.</p>

    <div class="terms-title-secondary">6. Integrations</div>
    <p>We may make available functionality allowing you to integrate other services (“Integration Service”) into {{('design' | env).legalName }}. Usage of such services will be governed by the Terms applicable for the Integration Service used.</p>
    <p>When using the Service or third-party integrations to the Service, you may be exposed to commercial messages and advertisements. The Website and the Service may contain links to third party websites. These links are provided solely as a convenience to you and not as an endorsement by {{('design' | env).legalName }} of the contents of such third party websites. {{('design' | env).legalName }} excludes any responsibility for the content of linked third party websites.</p>

    <div class="terms-title-secondary">7. Intellectual Property Rights</div>
    <p>All rights, title to and interest in the Service (excluding User Content) are and will remain the exclusive property of {{('design' | env).legalName }}. The Service and its content are protected by copyright and other intellectual property rights in the country you reside as well as foreign countries.</p>
    <p>Except for the limited, personal, non-exclusive, non-transferable and revocable license granted to you for the sole purpose of your use of the Service in compliance with the Terms, you are not granted any ownership of or license to any intellectual property rights in our Service or in the content owned by us our partners that you access through the Service.</p>
    <p>The trademarks, logos, domain names and any other similar signs or symbols which are displayed on the Website or as part of the Service are the registered and unregistered marks of {{('design' | env).legalName }}. Nothing in the Terms grants you the right to use any such marks.</p>
    <p>You retain your rights to any Content you submit, post or display on or through the Service. By submitting User Content, you grant {{('design' | env).legalName }}, or must procure that your licensors grant {{('design' | env).legalName }}, a non-exclusive, royalty-free, perpetual, transferable, sub-licensable, irrevocable and worldwide license to use, reproduce, store, modify, distribute, publish and create derivative works of such User Content for any purpose. You represent and warrant that you own any User Content submitted by you or that you otherwise are entitled to submit such User Content and to grant us such license.</p>

    <div class="terms-title-secondary">8. Termination</div>
    <p>You may stop using our Service, by deleting your account in Settings on your personal account on the website. As a subscriber of the paid version, you may terminate your subscription at any time, and will have access to the Service for the remaining billing (pre-paid) period. The Company reserves the right to terminate the Service and the agreement with you with immediate effect upon written notice to you. No users are entitled to refunds upon termination due to breach of these Terms.</p>
    
    <div class="terms-title-secondary">9. Miscellaneous</div>
    <p><strong>Disclaimer of warranty.</strong> {{('design' | env).legalName }} provides the Service to you as is. You acknowledge that the Service is not error-free. You use it at your own risk and discretion. That means the Service doesn’t come with any warranty. None express, none implied. {{('design' | env).legalName }} makes no warranties, expressed or implied, with respect to the availability, merchantability, fitness for a particular purpose, non-infringement, accuracy, completeness, performance and quality of the Service. {{('design' | env).legalName }} will from time to time have to carry out updates and maintenance of the Service, due to technical, security or operational reasons, during which time the Service might be unavailable. {{('design' | env).legalName }} will make reasonable efforts to arrange updates and maintenance outside of peak usage hours.</p>
    <p><strong>Limitation of Liability.</strong> {{('design' | env).legalName }} shall not be liable for any damages, whether arising under law, contract, warranty, indemnification, tort or otherwise, including, without limitation, incidental and consequential damages, loss of profits or business opportunities, or damages resulting from loss of data or loss of access to the Service. In any event, {{('design' | env).legalName }}'s total liability shall not exceed the amount paid by you for the Service during the last 12 months prior to the incident that causes the liability.</p>
    <p><strong>Indemnity.</strong> You agree to indemnify, defend and hold harmless {{('design' | env).legalName }} and its partners from all claims, liabilities and expenses (including reasonable attorney's fees) that arise from your misuse of the Service in breach of the Terms or applicable laws. {{('design' | env).legalName }} reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you. You shall cooperate in good faith with {{('design' | env).legalName }} in asserting any available defenses.</p>
    <p><strong>Partial invalidity.</strong> If any provision of the Terms is declared invalid or unenforceable by a court or other binding authority, the remaining terms (or parts), conditions and provisions shall continue to be valid and enforceable to the fullest extent permitted by law.</p>
    <p><strong>Force majeure.</strong> Force Majeure means any circumstances beyond the reasonable control of either party, including, without limitation, fire, explosion, strikes or other labor disputes, riots or other civil disturbances, voluntary or involuntary compliance with any law, order regulation, recommendation or request of any governmental authority, and errors or downtime in networks, power supply, gateway or similar failures of communication. Neither party will have any liability, other than for the payment of money owing, for their failure to perform any of their contractual obligations arising out of or in connection with events of force majeure.</p>
    <p><strong>Assignment.</strong> {{('design' | env).legalName }} is entitled, in whole or in part, to assign its rights and obligations under the Terms to a third party at its own discretion.</p>
    <p><strong>Choice of Law and Dispute Resolution.</strong> The Terms shall be governed by and interpreted in accordance with law of {{('design' | env).termsCountryAndDistrict.country }}. Any dispute, controversy or claim arising out of or in connection with the Terms shall be subject to the jurisdiction of the {{('design' | env).termsCountryAndDistrict.country }} courts with the district court of {{('design' | env).termsCountryAndDistrict.district }}, {{('design' | env).termsCountryAndDistrict.country }} as the legal venue.</p>
  </div>
</div>
<div class="session-list">
    <div class="card">
        <div class="card-header">
            <!--<div class="header px-3 d-flex justify-content-between align-items-center">
                <span>{{ 'APP.MAIN.SESSIONS.SESSION_LIST.ALL_SESSIONS' | translate }}</span>
                <button class="btn btn-info btn-sm">Apply Filters</button>
            </div>-->
            <div class="filters container-fluid">
                <div class="row">
                    <div class="col-sm-6 col-md-12 col-lg-6 col-xl-4 mt-2">
                        <input #filterDates="bsDaterangepicker" name="filterDates" [(ngModel)]="filterStartEnd" class="form-control datepicker-input" bsDaterangepicker
                            placeholder="{{ 'APP.MAIN.SESSIONS.SESSION_LIST.START_END' | translate }}" [bsConfig]="{ rangeInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue' }" onkeypress="return false;">
                    </div>
                    <div class="col-sm-6 col-md-12 col-lg-6 col-xl-4 mt-2">
                        <ng-select [items]="rooms" name="rooms" bindLabel="name" bindValue="id" [clearSearchOnAdd] = "true" [closeOnSelect]="false"
                            placeholder="{{ 'APP.MAIN.SESSIONS.SESSION_LIST.ALL_ROOMS' | translate }}" [multiple]="true" [(ngModel)]="filterRooms">
                        </ng-select>
                    </div>
                    <div class="col-sm-6 col-md-12 col-lg-6 col-xl-4 mt-2">
                        <ng-select [items]="users" name="users" bindLabel="name" bindValue="id" [clearSearchOnAdd] = "true" [closeOnSelect]="false"
                            placeholder="{{ 'APP.MAIN.SESSIONS.SESSION_LIST.ALL_USERS' | translate }}" [multiple]="true" [(ngModel)]="filterUsers">
                        </ng-select>
                    </div>
                </div>
                <div class="row mt-4 mb-3" *ngIf="showSessionRatingFilters">
                    <div class="col-sm-4 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center mt-1">
                        <span class="mr-2" style="min-width: 80px; font-size: 12px; color: #999;" data-placement="bottom" [tooltipDelay]="0"
                            [dynamicTooltip]="'APP.MAIN.SESSIONS.SESSION_LIST.CLIENT_Q1_FULL'">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.CLIENT_Q1' | translate }}</span>
                        <ng-select style="flex-grow: 1;" [items]="[1,2,3,4,5,6,7,8,9,10]" name="fq1" [multiple]="false" [clearable]="true" [(ngModel)]="sessionRatingFilters.fq1"></ng-select>
                    </div>
                    <div class="col-sm-4 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center mt-1">
                        <span class="mr-2" style="min-width: 80px; font-size: 12px; color: #999;" data-placement="bottom" [tooltipDelay]="0"
                            [dynamicTooltip]="'APP.MAIN.SESSIONS.SESSION_LIST.CLIENT_Q2_FULL'">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.CLIENT_Q2' | translate }}</span>
                        <ng-select style="flex-grow: 1;" [items]="[1,2,3,4,5,6,7,8,9,10]" name="fq2" [multiple]="false" [clearable]="true" [(ngModel)]="sessionRatingFilters.fq2"></ng-select>
                    </div>
                    <div class="col-sm-4 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center mt-1">
                        <span class="mr-2" style="min-width: 80px; font-size: 12px; color: #999;" data-placement="bottom" [tooltipDelay]="0"
                            [dynamicTooltip]="'APP.MAIN.SESSIONS.SESSION_LIST.CLIENT_Q3_FULL'">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.CLIENT_Q3' | translate }}</span>
                        <ng-select style="flex-grow: 1;" [items]="[1,2,3,4,5,6,7,8,9,10]" name="fq3" [multiple]="false" [clearable]="true" [(ngModel)]="sessionRatingFilters.fq3"></ng-select>
                    </div>
                    <div class="col-sm-4 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center mt-1">
                        <span class="mr-2" style="min-width: 80px; font-size: 12px; color: #999;" data-placement="bottom" [tooltipDelay]="0"
                            [dynamicTooltip]="'APP.MAIN.SESSIONS.SESSION_LIST.CLIENT_Q4_FULL'">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.CLIENT_Q4' | translate }}</span>
                        <ng-select style="flex-grow: 1;" [items]="[1,2,3,4,5,6,7,8,9,10]" name="fq4" [multiple]="false" [clearable]="true" [(ngModel)]="sessionRatingFilters.fq4"></ng-select>
                    </div>
                    <div class="col-sm-4 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center mt-1">
                        <span class="mr-2" style="min-width: 80px; font-size: 12px; color: #999;" data-placement="bottom" [tooltipDelay]="0"
                            [dynamicTooltip]="'APP.MAIN.SESSIONS.SESSION_LIST.EXECUTIVE_Q1_FULL'">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.EXECUTIVE_Q1' | translate }}</span>
                        <ng-select style="flex-grow: 1;" [items]="[1,2,3,4,5,6,7,8,9,10]" name="eq1" [multiple]="false" [clearable]="true" [(ngModel)]="sessionRatingFilters.eq1"></ng-select>
                    </div>
                    <div class="col-sm-4 col-md-6 col-lg-4 col-xl-9 d-flex align-items-center mt-1 justify-content-end" style="font-size: 12px; color: #999;">
                        <div><span class="required-field">*</span>{{ 'APP.MAIN.SESSIONS.SESSION_LIST.SESSION_RATING_MESSAGE' | translate }}</div>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col-12 text-right mt-2">
                        <button class="btn btn-info btn-sm" (click)="applyFilters()">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.APPLY_FILTERS' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body px-0 py-1">
            <div class="cumulative-data" *ngIf="sessionStats">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 mt-2 mb-3">
                            <span class="cumulative-title text-muted">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.SESSIONS' | translate }}
                                <ng-container *ngIf="isFilterAvailable">
                                    {{ currentStart | date:'dd-MM-yyyy' }}
                                    <span *ngIf="currentEnd"> - {{ currentEnd | date:'dd-MM-yyyy' }}</span>
                                </ng-container>
                            </span>
                        </div>
                        <div class="col-md-6 mt-2"><span class="key">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_COUNT' | translate }}: </span><span class="value">{{ sessionStats.count }}</span></div>
                        <div class="col-md-6 mt-2"><span class="key">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.TOTAL_DURATION' | translate }}: </span><span class="value">{{ sessionStats.duration }}</span></div>
                        <div class="col-12 my-2">
                            <div class="key my-2">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.PARTICIPANTS' | translate }}:</div>
                            <div class="row">
                                <div class="col-md-6 mt-1" *ngFor="let user of (sessionStats.users | objectToArray)"><span class="key">{{ user.id | userName | async }}</span><span>  {{ user.count }} {{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.SESSIONS' | translate }}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 2px; background-color:#dbe2e7;"></div>
            <div class="pager-container">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-8">
                            <div class="export-btn-group btn-group" dropdown>
                                <button id="dropdown-button" dropdownToggle type="button" class="btn btn-sm btn-info dropdown-toggle d-flex align-items-center" aria-controls="export-menu">
                                    <i class="mi mi-cloud-download mr-1"></i><span>{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.EXPORT' | translate }}</span>
                                </button>
                                <ul id="export-menu" *dropdownMenu class="dropdown-menu dropdown-menu-right py-1" role="menu" aria-labelledby="dropdown-button">
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="onSessionExport()">CSV</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-4 text-right">
                            <div class="btn-group" role="group">
                                <button (click)="prev()" [disabled]="page === 1" type="button" class="btn btn-sm btn-info">&#60;</button>
                                <button type="button" class="btn btn-light btn-page" disabled>{{ page }} / {{ pageCount }}</button>
                                <button (click)="next()" [disabled]="page === pageCount || sessions?.length === 0" type="button" class="btn  btn-sm btn-info">&#62;</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 2px; background-color:#dbe2e7;"></div>

            <div class="text-center my-2" *ngIf="!sessions">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.LOADING' | translate }}</div>
            <div class="text-center my-2" *ngIf="sessions && sessions.length === 0">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.NO_SESSION_MESSAGE' | translate }}</div>
            <div class="t-container container-fluid" *ngIf="sessions && sessions.length !== 0">
                <div class="t-head row sm-hide pl-3">
                    <div class="t-cell col-1">#</div>
                    <div class="t-cell col-2">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.DATE' | translate }} ({{ timezone }})</div>
                    <div class="t-cell col-3">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.ROOM_NAME' | translate }}</div>
                    <div class="t-cell col-2">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.PARTICIPANTS' | translate }}</div>
                    <div class="t-cell col-2">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.STATUS' | translate }}</div>
                    <div class="t-cell col-2">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.DURATION' | translate }}</div>
                </div>
    
                <div class="t-row row pl-3" *ngFor="let session of sessions; index as i;" (click)="onSessionDetails(session)">
                    <div class="t-cell col-1 sm-hide">{{ i+1 + (page-1)*offset }}</div>
                    <div class="t-cell sm-show col-6">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.DATE' | translate }}</div>
                    <div class="t-cell col-6 col-lg-2">{{ session.create_time | utcCalc | async }}</div>
                    <div class="t-cell sm-show col-6">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.ROOM_NAME' | translate }}</div>
                    <div class="t-cell col-6 col-lg-3">
                        {{ session.room_id | roomName | async }}
                        <ng-container *ngIf="session.session_ratings">
                            <span class="badge badge-primary" *ngIf="session.session_ratings.expert">{{ session.session_ratings.expert.IS_TOOL_ACCURATE }}</span>
                            <ng-container *ngIf="session.session_ratings.field">
                                <span class="badge badge-secondary">{{ session.session_ratings.field.SUPPORT_HELPFUL }}</span>
                                <span class="badge badge-secondary">{{ session.session_ratings.field.CLEAR_AND_EASY }}</span>
                                <span class="badge badge-secondary">{{ session.session_ratings.field.IS_RESOLVED }}</span>
                                <span class="badge badge-secondary">{{ session.session_ratings.field.USE_IN_FUTURE }}</span>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="t-cell sm-show col-6">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.PARTICIPANTS' | translate }}</div>
                    <div class="t-cell col-6 col-lg-2">
                        <div *ngFor="let name of session.users | getIds | filterGuests:session.guest_users | userNames | async" class="mt-1">{{ name }}</div>
                        <div *ngFor="let guest of session.guest_users | objectToArray" class="mt-1">{{ guest.name }} <span class="text-info">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.GUEST' | translate }}</span></div>
                    </div>
                    <div class="t-cell sm-show col-6">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.STATUS' | translate }}</div>
                    <div class="t-cell col-6 col-lg-2">
                        <span class="badge" [ngClass]="{'badge-success': !session.end_time, 'badge-secondary': session.end_time}">{{ (session.end_time ? 'APP.MAIN.SESSIONS.SESSION_LIST.ENDED' : 'APP.MAIN.SESSIONS.SESSION_LIST.ON_GOING') | translate }}</span>
                    </div>
                    <div class="t-cell sm-show col-6">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.DURATION' | translate }}</div>
                    <div class="t-cell col-6 col-lg-2">{{ session | sessionDuration }}</div>
                </div>
            </div>
        </div>
    </div>
</div>